import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'

//Components
import Layout from "../components/layout"
import Team from "../components/team"

const TeamPage = () => (
  <Layout>
    <Team/>
  </Layout>
)

export default TeamPage
